import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '../views/auth/auth.vue'
import { toLink, getIsBack, removeIsBack } from '@/utils/index.js'
import { trackingRouterGuards, browserBackInit } from '@/utils/tracking.js'
import { addWxConfig } from '@/utils/wxConfig.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:qrCode/:projectCode',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/index',
    name: 'Index',
    component: () =>
      import(/* webpackChunkName: "index" */ '../views/index/index.vue')
  },
  {
    path: '/activity-not-start',
    name: 'activityNotStart',
    component: () =>
      import(
        /* webpackChunkName: "activity-not-start" */ '../views/activity-not-start/activity-not-start.vue'
      )
  },
  {
    path: '/activity-end',
    name: 'activityEnd',
    component: () =>
      import(
        /* webpackChunkName: "activity-end" */ '../views/activity-end/activity-end.vue'
      )
  },
  {
    path: '/scan-log',
    name: 'ScanLog',
    component: () =>
      import(
        /* webpackChunkName: "scan-log" */ '../views/scan-log/scan-log.vue'
      )
  },
  {
    path: '/project-state',
    name: 'projectState',
    component: () =>
      import(
        /* webpackChunkName: "project-state" */ '../views/project-state/project-state.vue'
      )
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE,
  routes
})

// 浏览器返回监听初始化
browserBackInit(router.getRoutes())

router.beforeEach((to, from, next) => {
  // 页面进入前收集
  trackingRouterGuards('before', to, from, getIsBack())

  next()
})

router.afterEach((to, from) => {
  if (to.name != 'Auth') {
    addWxConfig()
  }

  // 页面进入后收集
  trackingRouterGuards('after', to, from, getIsBack())

  if (getIsBack()) {
    console.log('是返回的')
    removeIsBack()
  }
  // 拦截所有a标签跳转
  Vue.nextTick(() => {
    let aEls = document.querySelectorAll('a')
    aEls.forEach(el => {
      el.removeEventListener('click', function () {}, false)
      el.addEventListener(
        'click',
        function (e) {
          toLink(e.target.href, e.target?.dataset?.flag || true)
          e.preventDefault()
        },
        false
      )
    })
  })
})

export default router
