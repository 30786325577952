var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('img',{attrs:{"src":_vm.$route.params.projectCode == '32010800'
        ? require('@/assets/auth2023-bg.png')
        : require('@/assets/auth.png'),"alt":"页面加载中"}}),_c('BaseNav',{attrs:{"indexs":[
      {
        index: 2,
        tracking: { userVisit: '42', sourceFlag: '10' }
      }
    ]}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showOverlay),expression:"showOverlay"}],staticClass:"overlay"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }