import Vue from 'vue'
import axios from 'axios'
import Qs from 'qs'
import { getSessionStorage } from '@/utils/storage.js'
import { isFunction } from '@/utils/index.js'

const setting = require('@/setting.js')
const url = setting.tracking.url
const trackingRouter = setting.tracking.scene
// 屏幕宽高
const screenWidth = window.screen.width
const screenHeight = window.screen.height
// 当前页面路由名
var currentRouteName = ''

// 收集路由守卫
export function trackingRouterGuards(type, to) {
  let routeName = to.name || ''
  // 进入路由前记录当前路由名称
  if (type == 'before') {
    currentRouteName = routeName
  }

  let target = trackingRouter[type]

  if (target && target[routeName]) {
    console.log('save接口调用完毕 路由' + type)
    sendTracking(target[routeName])
  }
}

// 埋点浏览器返回初始化
export function browserBackInit(routes) {
  console.log('browserBackInit', routes)
  routes = routes.map(route => {
    return {
      path: process.env.VUE_APP_ROUTER_BASE + route.path,
      name: route.name
    }
  })
  console.log('browserBackInit', routes)

  let p = new Promise(function (resolve) {
    // 监听通过链接跳转后(ios 和 android)返回的
    window.addEventListener(
      'pageshow',
      function (e) {
        if (
          e.persisted ||
          (window.performance && window.performance.navigation.type == 2)
        ) {
          resolve(e.persisted)
        }
      },
      false
    )

    // 监听vue router history返回
    window.addEventListener(
      'popstate',
      function () {
        resolve()
      },
      false
    )
  })
  p.then(isIosReload => {
    let pathname = window.location.pathname
    let target = routes.find(route => route.path == pathname)
    let params = trackingRouter['back'][target?.name]
    console.log('埋点浏览器返回 sendTracking', params)
    sendTracking(params)
    if (isIosReload) {
      window.location.reload()
    }
  })
}

// 发送埋点数据
export function sendTracking(data) {
  let projectCode = getSessionStorage('projectCode')
  let qrCode = getSessionStorage('qrCode')
  let params = Object.assign(
    {
      projectCode: projectCode,
      qrCode: qrCode,
      fromType: 0,
      screenLength: screenHeight,
      screenWidth: screenWidth
    },
    data
  )

  //请求发送方式
  if (navigator.sendBeacon) {
    let formData = new FormData()
    for (let key in params) {
      formData.append(key, params[key])
    }
    navigator.sendBeacon(url, formData)
  } else {
    axios.post(url, Qs.stringify(params))
  }
}

//
let callbacks = []
let pending = false

// 执行所有回调函数
function flushCallbacks() {
  pending = false
  const copies = callbacks.slice(0)
  callbacks.length = 0
  let p = []
  for (let i = 0; i < copies.length; i++) {
    p.push(copies[i]())
  }
  Promise.all(p)
    .then(() => {
      console.log('save接口调用完毕', currentRouteName)
      sendTracking(trackingRouter['nextRequest'][currentRouteName])
    })
    .catch(() => {
      console.log('save接口调用失败')
    })
}

// 所有请求函数推入队列之后执行
export function nextRequest(fn) {
  console.log('requestAfter')
  if (isFunction(fn)) {
    callbacks.push(fn)
  }

  if (!pending) {
    pending = true
    // 延迟执行所有回调函数
    setTimeout(() => {
      console.log('callbacks:: ', callbacks)
      flushCallbacks()
    })
  }
}

// 注册一个全局自定义指令 `v-tracking`
export default Vue.directive('tracking', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function (el, binding) {
    el.addEventListener(
      'click',
      function () {
        console.log('v-tracking', binding.value)
        sendTracking(binding.value)
      },
      false
    )
  }
})
