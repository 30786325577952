<template>
  <div class="base-nav">
    <ol class="nav__main">
      <li v-for="(item, index) in navList" :key="index">
        <div v-if="item.type == 'applet'" class="nav__item">
          <i class="ico">
            <img :src="item.ico" width="100%" height="100%" :alt="item.title" />
          </i>
          <p>{{ item.title }}</p>
          <wx-open-launch-weapp
            :username="item.username"
            :path="item.path"
            class="launch-btn"
            v-tracking="item.tracking"
          >
            <script type="text/wxtag-template">
              <style>.weapp-btn {width: 100vw; height: 100vw;}</style>
              <div class="weapp-btn">&nbsp;</div>
            </script>
          </wx-open-launch-weapp>
        </div>
        <div
          v-else-if="item.type == 'link'"
          class="nav__item"
          v-tracking="item.tracking"
        >
          <i class="ico">
            <img :src="item.ico" width="100%" height="100%" :alt="item.title" />
          </i>
          <p>{{ item.title }}</p>
          <a :href="item.link" class="nav__link"></a>
        </div>
        <div
          v-else
          @click="onLink(item.link)"
          class="nav__item"
          v-tracking="item.tracking"
        >
          <i class="ico">
            <img :src="item.ico" width="100%" height="100%" :alt="item.title" />
          </i>
          <p>{{ item.title }}</p>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'BaseNav',
  props: {
    indexs: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      navListData: [
        {
          ico: require('@/assets/nav-ico-msgm.png'),
          title: '马上购买',
          type: 'applet',
          username: 'gh_3fdbfa81d7d8',
          path: 'pages/firstPage/firstPage',
          link: 'weixin://dl/business/?t=SSqvAg3R2Ua'
        },
        {
          ico: require('@/assets/nav-ico-hngw.png'),
          title: '红牛官网',
          type: 'link',
          link: 'http://www.redbull.com.cn'
        },
        {
          ico: require('@/assets/nav-ico-smjl.png'),
          title: '个人扫码记录',
          link: '/scan-log'
        }
      ]
    }
  },
  computed: {
    navList() {
      return this.indexs.map(item => {
        return Object.assign({}, item, this.navListData[item.index - 1])
      })
    }
  },
  methods: {
    onLink(link) {
      if (link) {
        this.$router.push({
          path: link
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.base-nav {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0px -3px 21px 0px rgba(0, 0, 0, 0.18);
  .nav__main {
    display: flex;
    width: 100%;
    li {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex: 1 0 auto;
      height: 100px;
      .nav__item {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow: hidden;
        .launch-btn {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 10;
        }
      }
      .ico {
        display: block;
        width: 104px;
        height: 50px;
        img {
          display: block;
        }
      }
      .nav__link {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        display: block;
        width: 100%;
        height: 100%;
      }
      &:after {
        content: ' ';
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 66px;
        background-color: #ebebeb;
      }
    }
    li:last-child {
      &:after {
        display: none;
      }
    }
    p {
      font-size: 20px;
      color: #333;
      width: 100%;
      text-align: center;
    }
  }
}
</style>
