import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import tracking from '@/utils/tracking'
import { nextRequest } from '@/utils/tracking'

Vue.config.productionTip = false
Vue.prototype.$nextRequest = nextRequest

import { Toast, Button, Popup, Checkbox } from 'vant'
Vue.use(Toast)
Vue.use(Button)
Vue.use(Popup)
Vue.use(Checkbox)

import BaseSupport from '@/components/base-support/base-support.vue'
import BaseNav from '@/components/base-nav/base-nav.vue'

Vue.component('BaseSupport', BaseSupport)
Vue.component('BaseNav', BaseNav)

new Vue({
  router,
  store,
  tracking,
  render: h => h(App)
}).$mount('#app')
