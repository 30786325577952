<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

#app {
  font-family: 'Microsoft YaHei', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.u-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 72px;
  line-height: 72px;
  background: linear-gradient(0deg, #f5c033, #fae77b, #fad54f);
  border: none;
  box-shadow: 0px 0 0 6px #c01a1c, 0px 4px 4px 0px rgba(255, 242, 190, 0.66),
    0px -4px 4px 0px rgba(240, 166, 49, 0.5);
  border-radius: 39px !important;
  font-size: 32px;
  font-weight: bold;
  //color: #e7471f;
  color: #fb232f;
  text-align: center;
}

.link {
  color: blue;
  text-decoration: underline;
}
</style>
